export const useAppVer = () => {
  const appVer = computed(() => {
    try {
      const { buildNumber } = useRuntimeConfig().public;
      return buildNumber || 0;
    } catch (error) {
      return 0;
    }
  });

  return { appVer };
};
